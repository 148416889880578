body {
    min-height: 100vh;
    height: 100%;
    display: grid;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.container {
    padding: 1rem;
    box-shadow: 5px 5px blue;
    border: 1px solid blue;
    max-width: 500px;
    height: fit-content;
}

#textbox,
code {
    word-break: break-all;
    padding: 0.25rem;
    display: block;
    background-color: #f1f1f1;
    color: blue;
}

form {
    display: flex;
    flex-direction: column;
}

button {
    height: 2rem;
    font-size: 18px;
    cursor: pointer;
}

form input[type="submit"] {
    width: fit-content;
    align-self: center;
    padding: 0.25rem;
    color: blue;
    background: papayawhip;
    border: 1px solid blue;
    border-radius: 6px;
    cursor: pointer;
}

* + * {
    margin-top: 0.5rem;
}

.button-group {
    display: flex;
    flex-direction: column;
}

hr {
    border-style: dashed;
    color: blue;
    border-width: 1px;
}

@media (min-width: 600px) {
    body {
        place-content: center;
    }
    
    .button-group {
        display: block;
    }
}